
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from 'react';
import PropTypes from 'prop-types';
import { buildContentfulImageURL } from '@utils/image';
import Image from '../../../../components/Image';
import s from './CollectionHero.module.scss';
import scarcityIcon from './imgs/scarcity-icon.svg';
const propTypes = {
    eyebrow: PropTypes.string,
    header: PropTypes.string,
    displayLogo: PropTypes.string,
    logoAltText: PropTypes.string,
    reducedHeight: PropTypes.bool
};
const scarcityImageSet = [
    {
        src: scarcityIcon
    },
];
const CollectionHero = ({ eyebrow = '', header = '', displayLogo = '', logoAltText = '', reducedHeight = false, }) => {
    const displayLogoSet = [
        {
            src: buildContentfulImageURL(displayLogo, { width: 400, format: 'webp' }),
            type: 'image/webp'
        },
        {
            src: buildContentfulImageURL(displayLogo, { width: 400, format: 'jpg' })
        },
    ];
    return (<div data-testid="collectionHeroRoot" className={[s.root, reducedHeight ? s.reducedHeight : ''].join(' ')}>
      {!displayLogo && (<>
          <div className={s.eyebrow}>{eyebrow}</div>
          <h1 className={s.header}>{header}</h1>
          <div className={s.scarcityIcon}>
            <Image imageSet={scarcityImageSet} contain/>
          </div>
        </>)}
      {displayLogo && (<div className={s.displayLogo}>
          <Image imageSet={displayLogoSet} contain alt={logoAltText || header}/>
        </div>)}
    </div>);
};
CollectionHero.propTypes = propTypes;
export default CollectionHero;

    async function __Next_Translate__getStaticProps__1948f158ab6__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'src//Collection/components/CollectionHero/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__1948f158ab6__ as getStaticProps }
  