
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    /* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import CollectionHero from './components/CollectionHero';
import CollectionHeroGradient from './components/CollectionHeroGradient';
import ShortDescription from './components/ShortDescription';
import CollectionReferenced from './components/CollectionReferenced';
import Promo from './components/Promo';
import Image from '../../components/Image';
import Layout from '../../components/MyLayout';
import VideoHero from '../../components/VideoHero';
import s from './Collection.module.scss';
import CollectionSpotlight from './components/CollectionSpotlight';
import MoreCollections from './components/MoreCollections';
import CollectionCarousel from './components/CollectionCarousel';
import CollectionSymbol from './components/CollectionSymbol';
import Lazy from '../../components/Lazy';
import HeadMeta from '../../components/HeadMeta';
import SegmentPage from '../../components/SegmentPage';
import { COLLECTION_THEME_COLORS } from '../../constants';
import { heroImageSet } from '../../utils/optimizeImage';
const propTypes = {
    collection: PropTypes.shape({
        theme: PropTypes.string,
        referencedContentCollectionDiscovery: PropTypes.array,
        collectionDiscoveryList: PropTypes.array,
        collectionDiscoveryEyebrow: PropTypes.string,
        collectionSymbol: PropTypes.shape({
            image: PropTypes.object
        }),
        contentTypeId: PropTypes.string,
        contentStaticVideo: PropTypes.shape({
            videoFileSource: PropTypes.string,
            mainImage: PropTypes.shape({}),
            displayTitle: PropTypes.string,
            duration: PropTypes.string,
            contentfulId: PropTypes.string,
            contentTypeId: PropTypes.string,
            displayTag: PropTypes.array,
            tags: PropTypes.array,
            activationDate: PropTypes.string
        }),
        contentfulId: PropTypes.string,
        displayTitle: PropTypes.string,
        displayTitleEyebrow: PropTypes.string,
        displayLogo: PropTypes.shape({
            file: PropTypes.object
        }),
        entryTitle: PropTypes.string,
        externalId: PropTypes.string,
        mainImage: PropTypes.shape({
            contentTypeId: PropTypes.string,
            videoFileSource: PropTypes.string
        }),
        mobileMainImage: PropTypes.shape({
            file: PropTypes.shape({
                url: PropTypes.string.isRequired
            })
        }),
        mainVideo: PropTypes.shape({
            videoFileSource: PropTypes.string
        }),
        referencedContent: PropTypes.array,
        secondaryReferencedContentEyebrow: PropTypes.string,
        secondaryReferencedContentCarousel: PropTypes.array,
        referencedContentEyebrowCarousel: PropTypes.string,
        referencedContentTwo: PropTypes.array,
        referencedContentEyebrowCarouselTwo: PropTypes.string,
        referencedContentStatic: PropTypes.array,
        referencedContentStaticEyebrow: PropTypes.string,
        searchDescription: PropTypes.string,
        searchTitle: PropTypes.string,
        shortDescription: PropTypes.string,
        shortDescriptionEyebrow: PropTypes.string,
        ctaLabel: PropTypes.string,
        ctaUrl: PropTypes.string,
        ctaIcon: PropTypes.shape({}),
        spotlight: PropTypes.shape({
            displayTitle: PropTypes.string,
            intro: PropTypes.string,
            author: PropTypes.shape({}),
            mainImage: PropTypes.shape({}),
            externalId: PropTypes.string,
            tags: PropTypes.array,
            activationDate: PropTypes.string,
            contentfulId: PropTypes.string,
            contentTypeId: PropTypes.string
        }),
        primaryPromo: PropTypes.shape({
            displayTitle: PropTypes.string,
            intro: PropTypes.string,
            author: PropTypes.shape({}),
            mainImage: PropTypes.shape({}),
            url: PropTypes.string,
            label: PropTypes.string,
            tags: PropTypes.array,
            contentfulId: PropTypes.string,
            contentTypeId: PropTypes.string,
            promoLogo: PropTypes.shape({})
        }),
        secondaryPromo: PropTypes.shape({
            displayTitle: PropTypes.string,
            intro: PropTypes.string,
            author: PropTypes.shape({}),
            mainImage: PropTypes.shape({}),
            url: PropTypes.string,
            label: PropTypes.string,
            tags: PropTypes.array,
            contentfulId: PropTypes.string,
            contentTypeId: PropTypes.string,
            promoLogo: PropTypes.shape({})
        }),
        spotlightCTA: PropTypes.string,
        spotlightEyebrow: PropTypes.string
    }),
    collectionDiscovery: PropTypes.array
};
const pageName = 'Detail';
const Collection = ({ collection = {}, collectionDiscovery = {} }) => {
    const { collectionDiscoveryList = [], collectionDiscoveryEyebrow, collectionSymbol, contentfulId, contentStaticVideo, displayTitleEyebrow, displayTitle, displayLogo, shortDescriptionEyebrow, shortDescription, referencedContentEyebrowCarousel, referencedContent = [], secondaryReferencedContentEyebrow, secondaryReferencedContentCarousel = [], referencedContentStaticEyebrow, referencedContentStatic = [], theme = 'Dark', spotlight, spotlightCTA, spotlightEyebrow, primaryPromo, secondaryPromo, searchTitle, searchDescription, externalId, ctaLabel, ctaUrl, ctaIcon, } = collection;
    const collectionTheme = COLLECTION_THEME_COLORS[theme];
    const mainImage = collection.mainImage?.image?.file?.url ?? '';
    const mobileMainImage = collection?.mobileMainImage?.image?.file?.url ?? '';
    const robots = collection?.metaTag ?? '';
    const heroVideo = collection?.mainVideo?.videoFileSource ?? null;
    const orderedDiscoveryCollections = collectionDiscoveryList.map((collectionId) => {
        return collectionDiscovery.find((discoveryCollection) => discoveryCollection.externalId === collectionId);
    });
    const containsVideo = !!contentStaticVideo || !!heroVideo;
    // This can be removed, and the video player rendered directly below
    // once we remove support for the legacy video player.
    const renderVideoComponent = () => {
        if (!contentStaticVideo)
            return null;
        let videoComponent = null;
        if (process.env.REACT_APP_VIDEO_PLAYER === 'TOP') {
            const VideoPlayer = dynamic(() => import('../../components/VideoPlayer'), {
                ssr: false
            });
            videoComponent = (<VideoPlayer videoUrl={contentStaticVideo.videoFileSource || ''} title={contentStaticVideo.displayTitle || ''} image={contentStaticVideo.mainImage?.image?.file?.url ?? ''} videoContent={contentStaticVideo} containerContent={{
                    contentName: displayTitle,
                    contentType: 'collection',
                    contentTags: [],
                    contentDisplayTag: [],
                    contentId: contentfulId
                }}/>);
        }
        else {
            const LegacyPromoVideoPlayer = dynamic(() => import('../../components/LegacyPromoVideoPlayer'), {
                ssr: false
            });
            videoComponent = (<LegacyPromoVideoPlayer videoData={contentStaticVideo} showOverlays={false}/>);
        }
        return videoComponent;
    };
    return (<Layout location={pageName}>
      <div className={s.root} style={{
            '--color-a': collectionTheme.colorA,
            '--color-b': collectionTheme.colorB
        }}>
        <HeadMeta title={searchTitle || displayTitle} description={searchDescription || shortDescription} canonicalUrl={`/collections/${externalId}`} ogImage={`${mainImage}?w=1200&fit=fill&f=top`} twitterImage={`${mainImage}?w=1200&fit=fill&f=top`} robots={robots}/>
        <SegmentPage pageName={pageName} otherProps={{
            content_name: displayTitle,
            content_type: 'Collections',
            content_id: contentfulId,
            content_tags: '',
            activation_date: '',
            contains_video: containsVideo
        }}/>
        <div className={s.heroBackground}>
          {heroVideo ? (<VideoHero src={heroVideo}/>) : (<Image imageSet={heroImageSet(mainImage, mobileMainImage)} cover/>)}
          <CollectionHeroGradient />
          <CollectionHero eyebrow={displayTitleEyebrow} header={displayTitle} displayLogo={displayLogo?.file?.url || ''} logoAltText={displayLogo?.description || ''}/>
          <CollectionSymbol symbol={collectionSymbol?.image?.file?.url ?? ''} className={s.symbol} {...(collectionSymbol?.image?.file?.details?.image ?? {})}/>
        </div>
        <ShortDescription shortDescription={shortDescription} eyebrow={shortDescriptionEyebrow} background={collectionTheme.colorA} ctaLabel={ctaLabel} ctaUrl={ctaUrl} ctaIcon={ctaIcon?.file?.url ?? ''}/>
        {contentStaticVideo && (<div className={s.collectionVideoWrapper}>
            <h3>{contentStaticVideo.displayTitle}</h3>
            {renderVideoComponent()}
          </div>)}
        {spotlight && (<CollectionSpotlight title={spotlightEyebrow} subTitle={spotlight.displayTitle} description={spotlight.intro} author={spotlight.author?.title ?? ''} image={spotlight?.teaserImage?.image?.file?.url ??
                spotlight?.mainImage?.image?.file?.url ??
                ''} externalId={spotlight.externalId} tags={spotlight.tags} contentfulId={spotlight.contentfulId} contentTypeId={spotlight.contentTypeId} color={collectionTheme.colorA} btnLabel={spotlightCTA}/>)}
        {primaryPromo && (<Promo displayTitle={primaryPromo.displayTitle} intro={primaryPromo.intro} image={primaryPromo.mainImage?.image?.file?.url ?? ''} label={primaryPromo.label} url={primaryPromo.url} pageName={pageName} tags={primaryPromo.tags} contentTypeId={primaryPromo.contentTypeId} contentfulId={primaryPromo.contentfulId} activationDate="" promoLogo={primaryPromo.promoLogo?.image?.file?.url ?? ''} promoType={'Primary Promo'}/>)}
        <Lazy>
          <CollectionCarousel eyebrow={referencedContentEyebrowCarousel} content={referencedContent} theme={theme}/>
        </Lazy>
        {secondaryPromo && (<Promo displayTitle={secondaryPromo.displayTitle} intro={secondaryPromo.intro} image={secondaryPromo.mainImage?.image?.file?.url ?? ''} label={secondaryPromo.label} url={secondaryPromo.url} pageName={pageName} tags={secondaryPromo.tags} contentTypeId={secondaryPromo.contentTypeId} contentfulId={secondaryPromo.contentfulId} activationDate="" promoLogo={secondaryPromo.promoLogo?.image?.file?.url ?? ''} promoType={'Secondary Promo'}/>)}
        <CollectionReferenced eyebrow={referencedContentStaticEyebrow} articles={referencedContentStatic} theme={theme}/>
        {secondaryReferencedContentCarousel.length > 0 && (<Lazy>
            <div className={s.secondaryCarousel}>
              <CollectionCarousel eyebrow={secondaryReferencedContentEyebrow} content={secondaryReferencedContentCarousel} theme={theme}/>
            </div>
          </Lazy>)}
        <MoreCollections data={orderedDiscoveryCollections} headline={collectionDiscoveryEyebrow} pageName={pageName} theme={theme}/>
      </div>
    </Layout>);
};
Collection.propTypes = propTypes;
export default Collection;

    async function __Next_Translate__getStaticProps__1948f15670f__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'src//Collection/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__1948f15670f__ as getStaticProps }
  