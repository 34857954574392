
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from 'react';
import PropTypes from 'prop-types';
import { buildContentfulImageURL } from '@utils/image';
import { findHubName } from '@utils/hubs';
import Image from '../../../../components/Image';
import AnchorLink from '../../../../components/AnchorLink';
import { trackEvent } from '../../../../utils/analytics';
import s from './CollectionReferenced.module.scss';
const propTypes = {
    articles: PropTypes.arrayOf(PropTypes.shape({
        externalId: PropTypes.string,
        tags: PropTypes.array,
        author: PropTypes.shape({}),
        mainImage: PropTypes.shape({}),
        displayTag: PropTypes.array,
        displayTitle: PropTypes.string,
        contentfulId: PropTypes.string,
        contentTypeId: PropTypes.string,
        activationDate: PropTypes.string
    })),
    eyebrow: PropTypes.string,
    theme: PropTypes.string
};
const CollectionReferenced = ({ articles = [], eyebrow = 'More for you', theme = 'Dark' }) => {
    return (<section className={s.root}>
      <h3 className={[s.eyebrow, theme === 'Light' ? s.eyebrowDark : ''].join(' ')}>{eyebrow}</h3>
      <div className={s.articleWrapper}>
        <div className={s.articles}>
          {articles.map((article) => {
            const { externalId, tags, author = {}, mainImage, displayTag, displayTitle, contentfulId, contentTypeId, activationDate, hideAuthor = false, } = article;
            const articleImage = mainImage?.image?.file?.url || '';
            const articleLink = tags ? `/${findHubName(tags)}/${externalId}` : '';
            const imageSet = [
                {
                    src: buildContentfulImageURL(articleImage, {
                        width: 708,
                        height: 544,
                        fit: 'fill',
                        focus: 'top',
                        format: 'webp'
                    }),
                    media: '(max-width: 768px)',
                    type: 'image/webp'
                },
                {
                    src: buildContentfulImageURL(articleImage, {
                        width: 708,
                        height: 544,
                        fit: 'fill',
                        focus: 'top',
                        format: 'jpg'
                    }),
                    media: '(max-width: 768px)',
                    type: 'image/jpeg'
                },
                {
                    src: buildContentfulImageURL(articleImage, {
                        width: 388,
                        height: 248,
                        fit: 'fill',
                        focus: 'top',
                        format: 'webp'
                    }),
                    type: 'image/webp'
                },
                {
                    src: buildContentfulImageURL(articleImage, {
                        width: 388,
                        height: 248,
                        fit: 'fill',
                        focus: 'top',
                        format: 'jpg'
                    }),
                    type: 'image/jpeg'
                },
            ];
            return (<article className={s.article} key={externalId} data-testid="article-wrapper">
                <AnchorLink href={articleLink} onClick={() => {
                    trackEvent('Content Clicked', {
                        content_name: displayTitle,
                        content_type: contentTypeId,
                        content_id: contentfulId,
                        activation_date: activationDate,
                        location: articleLink,
                        carousel_index: '',
                        carousel_name: ''
                    });
                }} data-testid="article-link">
                  <div className={s.image}>
                    <Image imageSet={imageSet} cover/>
                  </div>
                  <div className={s.wrapper}>
                    <div className={s.tag}>{displayTag && displayTag[0].displayName}</div>
                    <h3 className={[s.header, theme === 'Light' ? s.headerDark : ''].join(' ')}>
                      {displayTitle}
                    </h3>
                    {!hideAuthor && (<div className={[s.by, theme === 'Light' ? s.byDark : ''].join(' ')}>
                        {`${author.displayTitle} ${author.title}`}
                      </div>)}
                  </div>
                </AnchorLink>
              </article>);
        })}
        </div>
      </div>
    </section>);
};
CollectionReferenced.propTypes = propTypes;
export default CollectionReferenced;

    async function __Next_Translate__getStaticProps__1948f158b30__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'src//Collection/components/CollectionReferenced/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__1948f158b30__ as getStaticProps }
  